<template>
  <div class="row">
    <div class="col-md-12 grid-margin">
      <div class="row">
        <div class="col-12 col-xl-8 mb-4 mb-xl-0">
          <h3 class="font-weight-bold white">Bem vindo ao Elementista</h3>
          <h6 class="font-weight-normal mb-0 white">Nossa missão é insprar <span class="orange">a próxima geração</span>
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card position-relative">
        <div class="card-body">
          <div id="detailedReports" class="carousel slide detailed-report-carousel position-static pt-2"
            data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <p class="card-title">Feed</p>
                <div class="row">
                  <div v-if="feedNoticia1" class="col-md-3" :key>
                    <a :href="feedNoticia1.url" target="_blank" style="text-decoration: none; color: inherit;">
                      <p v-html="sanitizeHtml(feedNoticia1.texto)"></p>
                      <img style="max-width: 100%; max-height: 270px;" :src="feedNoticia1.link" />
                    </a>
                  </div>
                  <div v-if="feedNoticia2" class="col-md-3 border-right" :key>
                    <a :href="feedNoticia2.url" target="_blank" style="text-decoration: none; color: inherit;">
                      <p v-html="sanitizeHtml(feedNoticia2.texto)"></p>
                      <img style="max-width: 100%; max-height: 270px;" :src="feedNoticia2.link" />
                    </a>
                  </div>
                  <div v-if="feedNoticia3" class="col-md-3" :key>
                    <a :href="feedNoticia3.url" target="_blank" style="text-decoration: none; color: inherit;">
                      <p v-html="sanitizeHtml(feedNoticia3.texto)"></p>
                      <img style="max-width: 100%; max-height: 270px;" :src="feedNoticia3.link" />
                    </a>
                  </div>
                  <div v-if="feedNoticia4" class="col-md-3 " :key>
                    <a :href="feedNoticia4.url" target="_blank" style="text-decoration: none; color: inherit;">
                      <p v-html="sanitizeHtml(feedNoticia4.texto)"></p>
                      <img style="max-width: 100%; max-height: 270px;" :src="feedNoticia4.link" />
                    </a>
                  </div>

                </div>

              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-6 ">
                    <div class="card">
                      <div class="card-body">
                        <p class="card-title">Notícias</p>
                        <p class="font-1 font-weight-500  text-primary">Estudantes participam de 1º Campeonato de
                          robótica da educação infantil em Boa Vista</p>
                        <p>
                          O campeonato
                          Assim, o campeonato é parte das atividades que as escolas tem que desenvolver com o estudantes
                          porque ganharam da empresa Positivo (Programa FIRST LEGO League Discover) com kits de Lego
                          entregues esse ano.
                          Do mesmo modo, a seleção, feita nacionalmente em 2021, contemplou seis escolas da região
                          Norte, cinco delas são de Boa Vista.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-6">
                    <div class="row">

                      <div class="col-md-12 mt-3">
                        <p class="card-title">Descobrindo a robótica na pré-escola</p>
                        <img class="mb-2 mb-xl-0" style="max-width: 390px; max-height: 270px;" :src="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#detailedReports" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#detailedReports" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card position-relative">
        <div class="card-body">
          <div id="detailedReports2" class="carousel slide detailed-report-carousel position-static pt-2"
            data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <p class="col-md-1 card-title">Post</p>
                <div class="row">
                  <div class="col-md-12 col-xl-9">
                    <div class="row">
                      <div v-if="postNoticia1" class="col-md-6 border-right">
                        <div class="card">
                          <div class="card-body">
                            <p class="card-title" v-html="sanitizeHtml(postNoticia1.titulo)"></p>
                            <p class="font-weight-500" v-html="sanitizeHtml(postNoticia1.texto)"> </p>

                          </div>
                        </div>
                      </div>
                      <div v-if="postNoticia2" class="col-md-6 mt-1">
                        <div class="card">
                          <div class="card-body">
                            <p class="card-title" v-html="sanitizeHtml(postNoticia2.titulo)"></p>
                            <p class="font-weight-500" v-html="sanitizeHtml(postNoticia2.texto)"> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <p class="col-md-1 card-title">Post</p>
                <div class="row">
                  <div class="row">
                    <div class="col-md-12 col-xl-9">
                      <div class="row">
                        <div v-if="postNoticia3" class="col-md-6 border-right">
                          <div class="card">
                            <div class="card-body">
                              <p class="card-title" v-html="sanitizeHtml(postNoticia3.titulo)"></p>
                              <p class="font-weight-500" v-html="sanitizeHtml(postNoticia3.texto)"> </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mt-1">
                          <div v-if="postNoticia4" class="card">
                            <div class="card-body">
                              <p class="card-title" v-html="sanitizeHtml(postNoticia4.titulo)"></p>
                              <p class="font-weight-500" v-html="sanitizeHtml(postNoticia4.texto)"> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#detailedReports2" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#detailedReports2" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <p class="card-title">Order Details</p>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <p class="card-title">Order Details</p>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
  </div> -->

</template>

<script>

import FeedImagem1 from "../../public/imgFeed/modelo1.jpg";
import FeedImagem2 from "../../public/imgFeed/modelo2.jpg";
import FeedImagem3 from "../../public/imgFeed/modelo3.jpg";
import apiMethodsAtividades from '../views/conteudo/service/service.atividades';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import DOMPurify from 'dompurify';

export default {
  name: 'HelloWorld',
  components: {
    QuillEditor
  },
  data() {
    return {
      img: FeedImagem1,
      img1: FeedImagem2,
      img2: FeedImagem3,
      feedNoticia1: {},
      feedNoticia2: {},
      feedNoticia3: {},
      feedNoticia4: {},
      postNoticia1: {},
      postNoticia2: {},
      postNoticia3: {},
      postNoticia4: {}

    }

  },
  created() {
    this.obterFeed();
    this.obterPost();
  },
  methods: {
    sanitizeHtml(html) {
      return DOMPurify.sanitize(html);
    },
    async obterFeed() {
      const dados = await apiMethodsAtividades.obterFeed()
      console.log("obterFeed", dados);

      const feed1 = dados
        .filter(feed => feed.id_ordem === 1) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          publicada: feed.publicada,
          url: feed.url,
          tag: feed.tag
        }));

      this.feedNoticia1 = feed1[0]

      const feed2 = dados
        .filter(feed => feed.id_ordem === 2) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));

      this.feedNoticia2 = feed2[0]

      const feed3 = dados
        .filter(feed => feed.id_ordem === 3) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          url: feed.url,
          dt_criacao: feed.dt_criacao,
          publicada: feed.publicada,
          tag: feed.tag
        }));

      this.feedNoticia3 = feed3[0]


      const feed4 = dados
        .filter(feed => feed.id_ordem === 4) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));

      this.feedNoticia4 = feed4[0]


    },
    async obterPost() {
      const dados = await apiMethodsAtividades.obterPost()

      console.log("obter post", dados);


      const post1 = dados
        .filter(post => post.id_ordem === 1) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag,
          url: post.url
        }));

      this.postNoticia1 = post1[0]

      const post2 = dados
        .filter(post => post.id_ordem === 2) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag
        }));

      this.postNoticia2 = post2[0]

      const post3 = dados
        .filter(post => post.id_ordem === 3) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag
        }));

      this.postNoticia3 = post3[0]


      const post4 = dados
        .filter(post => post.id_ordem === 4) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag
        }));

      this.postNoticia4 = post4[0]


    }
  }


}
</script>

<style scoped>
.font-1 {
  font-size: 20px;
  line-height: 1.6;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
